import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import Point from 'ol/geom/Point'
import * as actions from '../../../../../Stores/Actions/actions';
import { Vector as LayerVector } from 'ol/layer'
import { Vector as SourceVector } from 'ol/source';
import Feature from 'ol/Feature';
import StyleEngine from './../Styles/StyleEngine';
import { WKT } from 'ol/format';
import { Draw, Modify, Snap } from 'ol/interaction';
import * as helpers from './../../../../../Utils/Helpers';
import clickZoom from 'ol/interaction/DoubleClickZoom';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';
import Overlay from 'ol/Overlay';
import { Ruler, StepBack, Trashcan, DrawLine, DrawPoly } from './../../../../Icons/Icons';
import { get } from 'ol/proj';
import Projection from 'ol/proj/Projection';


const mapStateToProps = (state, ownProps) => {
  return {
    //MapFeatures: state.map_Features ? state.map_Features[ownProps.swid] : []
  };
}

export class Measurement extends PureComponent {
  state = {
    showControls: false
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  doubleClickZoomEnabled = (isActive) => {
    var interactions = this.props.map.getInteractions().getArray();
    interactions.forEach(x => {
      if (x instanceof clickZoom) {
        x.setActive(isActive);
      }
    });
  }

  getMeasurementVector = () => {
    let layers = this.props.map.getLayers().getArray();
    let measureLayer = layers.find(x => x.LayerId === 'Measure');
    let vector;

    if (!measureLayer) {
      vector = new SourceVector();
      let layer = new LayerVector({
        renderMode: 'vector',
        visible: true,
        source: vector,
        updateWhileInteracting: true,
        style: [new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(46, 41, 0, 1)',
            width: 6,
          }),
        }), new Style({
          source: vector,
          type: 'LineString',
          stroke: new Stroke({
            color: 'rgba(255, 190, 10, 0.9)',
            width: 3,
          }),
        }),],
      });
      let modify = new Modify({
        source: vector,
        style: new Style({
          image: new CircleStyle({
            radius: 6,
            stroke: new Stroke({
              width: 2,
              color: 'rgba(46, 41, 0, 1)',
            }),
            fill: new Fill({
              color: 'rgba(255, 190, 10, 0.9)',
            }),
          }),
        })
      });
      this.props.map.addInteraction(modify);
      layer.setZIndex(1111);
      layer.LayerId = 'Measure';
      layer.Vector = vector;
      this.props.map.addLayer(layer);
    } else {
      vector = measureLayer.Vector;
    }
    return vector;
  }

  formatLength = (line) => {
    let length = getLength(line);
    let outputConversion = length * 3.28084;
    let roundedFt = Math.round(outputConversion * 100) / 100 + ' ' + 'ft';
    let output = roundedFt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return output;
  };
  formatArea = (polygon, mode) => {
    let area = getArea(polygon);
    let outputConversion;
    let roundedOutput;
    if (mode === 'area-acres') {
      outputConversion = area / 4047;
      roundedOutput = Math.round(outputConversion * 100) / 100 + ' ' + 'acres';
    } else if (mode === 'area-ft') {
      outputConversion = area * 10.7639;
      roundedOutput = Math.round(outputConversion * 100) / 100 + ' ' + 'ft<sup>2</sup>';
    }
    let output = roundedOutput.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return output;
  };

  lineMeasure = () => {
    let vector = this.getMeasurementVector();
    let draw = new Draw({
      source: vector,
      type: 'LineString',
      style: [new Style({
        stroke: new Stroke({
          color: 'rgba(46, 41, 0, 1)',
          lineDash: [10, 10],
          width: 6,
        }),
        image: new CircleStyle({
          radius: 6,
          stroke: new Stroke({
            width: 2,
            color: 'rgba(46, 41, 0, 1)',
          }),
          fill: new Fill({
            color: 'rgba(255, 190, 10, 0.9)',
          }),
        }),
      }), new Style({
        source: vector,
        type: 'LineString',
        stroke: new Stroke({
          color: 'rgba(255, 190, 10, 0.9)',
          lineDash: [10, 10],
          width: 3,
        }),
      }),],
    });
    this.createMeasurement(draw);
  }

  createMeasurement = (draw) => {
    let sketch;
    let listener;
    let measureTooltip;
    let measureTooltipElement;
    let unitType = 'area-ft';
    let createMeasureTooltip = () => {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement('div');
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
      measureTooltipElement.onclick = (e) => {
        let element = e.path[0];
        let mode = element.classList.contains('area-acres') ? 'area-ft' : 'area-acres';
        element.classList.remove('area-ft');
        element.classList.remove('area-acres');
        element.classList.add(mode);
        unitType = mode;
        if (element.innerHTML.includes('<sup>') || element.innerHTML.includes('acre')) { //only allows polygons to switch
          this.changeMeasurement(e, mode);
        }
      }
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
      });
      this.props.map.addOverlay(measureTooltip);
    }

    draw.on('drawstart', (evt) => {
      // set sketch
      sketch = evt.feature;

      /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
      let tooltipCoord = evt.coordinate;

      listener = sketch.getGeometry().on('change', (evt) => {
        let geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom, unitType);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      });
    });

    draw.on('drawend', (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.feature.geometry = draw.type_.toUpperCase();
      this.props.map.removeInteraction(draw);
      this.doubleClickZoomEnabled(false);
      setTimeout(() => { this.doubleClickZoomEnabled(true); }, 251);
    });

    this.props.map.addInteraction(draw);
    createMeasureTooltip();
  }

  changeMeasurement = (e, mode) => {
    let innerHTML = e.path[0].innerHTML
    let indexOfSpace = innerHTML.indexOf(' ');
    let number = innerHTML.substring(0, indexOfSpace);
    let unformattedNumber = number.replaceAll(',', '');
    if (mode === 'area-acres' && innerHTML.includes('ft<sup>2</sup>')) {
      let acres = unformattedNumber / 43560;
      let roundedAcres = Math.round(acres * 100) / 100 + ' ' + 'acres';
      let output = roundedAcres.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      e.path[0].innerHTML = output;
    } else if (mode === 'area-ft') {
      let feet = unformattedNumber * 43560;
      let roundedFeet = Math.round(feet * 100) / 100 + ' ' + 'ft<sup>2</sup>';
      let output = roundedFeet.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      e.path[0].innerHTML = output;
    }
  }

  polyMeasure = () => {
    let vector = this.getMeasurementVector();
    let draw = new Draw({
      source: vector,
      type: 'Polygon',
      style: [new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.3)',
        }),
        stroke: new Stroke({
          color: 'rgba(46, 41, 0, 1)',
          lineDash: [10, 10],
          width: 6,
        }),
        image: new CircleStyle({
          radius: 6,
          stroke: new Stroke({
            width: 2,
            color: 'rgba(46, 41, 0, 1)',
          }),
          fill: new Fill({
            color: 'rgba(255, 190, 10, 0.9)',
          }),
        }),
      }), new Style({
        source: vector,
        type: 'Polygon',
        stroke: new Stroke({
          color: 'rgba(255, 190, 10, 0.9)',
          lineDash: [10, 10],
          width: 3,
        }),
      }),],
    });
    this.createMeasurement(draw);
  }

  clearAllMeasures = () => {
    let clearTooltips = (className) => {
      let tooltips = document.getElementsByClassName(className);
      while (tooltips.length > 0) {
        tooltips[0].parentNode.removeChild(tooltips[0]);
      }
    }
    let measureLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
    if (measureLayer) {
      let fets = measureLayer.getSource().getFeatures();
      fets.forEach(x => measureLayer.getSource().removeFeature(x));
      clearTooltips('ol-tooltip');
    }
  }

  clearLastMeasure = () => {
    let clearLastTooltip = (className) => {
      let tooltips = document.getElementsByClassName(className);
      tooltips[0].parentNode.removeChild(tooltips[0])
    }
    let measureLayer = this.props.map.getLayers().getArray().find(x => x.LayerId === 'Measure');
    let measureLayerLength = measureLayer.getSource().getFeatures().length;
    if (measureLayer && measureLayerLength > 0) {
      let featureToRemove = measureLayer.getSource().getFeatures()[measureLayerLength - 1];
      measureLayer.getSource().removeFeature(featureToRemove);
      clearLastTooltip('ol-tooltip');
    }
  }

  toggleControls = () => {
    let swap = !this.state.showControls;
    this.setState({ showControls: swap });
  }

  render() {
    return (
      <div>
        <div className="map-control">
          <div className="map-measure-open" onClick={this.toggleControls}>
            <Ruler />
          </div>
        </div>
        {this.state.showControls &&
          <div className="map-measure-controls">
            <div className={"map-measure-buttons"} onClick={this.lineMeasure}><DrawLine /></div>
            <div className={"map-measure-buttons"} onClick={this.polyMeasure}><DrawPoly /></div>
            <div className={"map-measure-buttons"} onClick={this.clearLastMeasure}><StepBack /></div>
            <div className={"map-measure-buttons"} onClick={this.clearAllMeasures}><Trashcan /></div>
            <div className={"map-measure-connector-piece"}></div>
          </div>}
      </div>
    )
  }
}

export default connect(mapStateToProps)(Measurement);


