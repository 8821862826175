import React from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import { defaults as defaultControls, Control } from 'ol/control';
import { LegendIcon } from './../../../../Icons/Icons';
import LegendItem from './LegendItem';

const mapStateToProps = (state) => {
  return {
    LegendItems: state.map_LegendItems
  };
}

export class LegendControl extends React.PureComponent {
  state = {
    isOpen: false
  }

  componentDidMount() {
    document.addEventListener('pointerdown', this.checkClose);
  }

  onClick = (e) => {
    e.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }

  checkClose = (e) => {
    if (this.state.isOpen && !helpers.elementOrAncestorHasClass(e.target, 'legend-control')) {
      this.setState({ isOpen: false });
    }
  }

  toggleLegendItem = (id) => {
    this.props.dispatch(actions.ToggleMapLayer(id));
  }

  render() {
    let legendItems = this.props.LegendItems ? this.props.LegendItems.filter(x => x.MapId === this.props.MapId) : [];
    return (
      this.state.isOpen ?
        <div className="legend-control open ol-unselectable ol-control">
          {legendItems.filter(x => x.ParentId === null).map((x, idx) => (
            <LegendItem key={idx} items={legendItems} toggleLegendItem={this.toggleLegendItem} {...x} />
          ))}
        </div>
        :
        <div className="legend-control ol-unselectable ol-control" onClick={this.onClick}>
          <LegendIcon />
        </div>
    );
  }
}

const legendComponent = connect(mapStateToProps, null, null, { forwardRef: true })(LegendControl);
export default helpers.OpenLayersCustomControl(Control, legendComponent);




